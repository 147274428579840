import s from './abaut-us.module.scss'

function Abaut() {

  return (
    <section className={s.abaut}>
      <h2 className={s.abaut__title}>Факты о нашей компании</h2>
      <p className={s.abaut__info}>Наша компания, основанная в 2017 году, успешно расширила свою деятельность по всей Европе. За годы работы мы не только достигли выдающихся результатов в создании оптимальных условий для наших сотрудников, но и завоевали доверие и высокую оценку наших партнёров. Статистика впечатляет – 99,8% клиентов остались довольны результатами нашего сотрудничества. Мы гордимся нашим прогрессом и стремимся к новым вершинам, предоставляя безупречный сервис по всему континенту.</p>
    </section>
  );
}

export default Abaut;